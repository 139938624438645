<template>
  <div
    v-if="showPaymentProvider"
    class="flex flex-col border border-bordercolor rounded-md h-36 p-3"
    @click="$emit('edit', data)"
  >
    <div class="flex justify-between">
      <div class="flex place-items-center">
        <img
          :src="
            data?.payment_gateway_method_application?.payment_gateway_method
              ?.payment_gateway?.image_url
          "
          alt=""
          class="h-6 opacity-30 hover:opacity-80"
          @click.stop.prevent="defaultChanges"
          :class="{
            'opacity-100': checkedDefaultValue,
          }"
        />

        <app-icon-loading
          v-if="defaultLoading"
          customClass="w-4 h-4 text-primary ml-1"
        />
        <p
          v-else-if="checkedDefaultValue && forOperation"
          class="text-xs text-gray-500 ml-1"
        >
          (default)
        </p>
      </div>
      <span class="flex flex-row items-center space-x-2" :class="[checkedDefaultValue ? 'opacity-100' : 'opacity-30']">
        <img
          :src="
            data?.payment_gateway_method_application?.payment_gateway_method
              ?.payment_method?.image_url
          "
          alt=""
        />
        <p class="text-xs">
          {{
            data?.payment_gateway_method_application?.payment_gateway_method
              ?.payment_method?.name
          }}
        </p>
      </span>
    </div>
    <div class="flex-auto"></div>
    <div class="flex justify-between" :class="[checkedDefaultValue ? 'opacity-100' : 'opacity-30']">
      <div v-if="showChannel" class="flex space-x-2 items-center">
        <p class="text-gray-400 text-sm">
          {{ $t("payment.settings.payment_provider.channel_used") }}
        </p>
        <div>
          <app-logo-senangpay
            class="mb-1"
            v-if="
              data?.payment_gateway_method_application?.payment_gateway_method
                ?.channel == PAYMENT_CHANNEL.SENANG_PAY
            "
          />
          <app-logo-gobiz
            class="w-20"
            v-else-if="
              data?.payment_gateway_method_application?.payment_gateway_method
                ?.channel == PAYMENT_CHANNEL.GO_BIZ
            "
          />
          <app-logo-m1pay
            class="h-3"
            v-else-if="
              data?.payment_gateway_method_application?.payment_gateway_method
                ?.channel == PAYMENT_CHANNEL.M1PAY
            "
          />
          <app-logo-stripe
            class="h-5"
            v-else-if="
              data?.payment_gateway_method_application?.payment_gateway_method
                ?.channel == PAYMENT_CHANNEL.STRIPE
            "
          />
          <p class="text-sm" v-else>
            {{
              data?.payment_gateway_method_application?.payment_gateway_method
                ?.channel
            }}
          </p>
        </div>
      </div>
      <div v-else></div>
      <div class="items-center">
        <app-form-switch :loading="loading" v-model="checkedValue" @click.stop>
          <p class="text-xs capitalize">{{ switchStatus }}</p>
        </app-form-switch>
      </div>
    </div>
  </div>
</template>

<script>
import PAYMENT_CHANNEL from "@/utils/const/payment_channel";

export default {
  data() {
    return {
      PAYMENT_CHANNEL: PAYMENT_CHANNEL,
      loading: false,
      defaultLoading: false,
    };
  },
  props: {
    data: {
      type: Object,
    },
    forOperation: {
      type: Boolean,
      default: false,
    },
    showChannel: {
      type: Boolean,
      default: false,
    },
    showPaymentProvider: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    async businessPaymentChanges(enabled) {
      this.resetState();
      this.loading = true;
      let businessPaymentMethod = this.data;
      businessPaymentMethod.enabled = enabled;
      await this.$store.dispatch(
        "paymentStore/createOrUpdateBusinessPaymentGatewayMethod",
        businessPaymentMethod
      );

      let business_id =
        businessPaymentMethod?.payment_gateway_method_application?.business_id;

      this.$store.dispatch(
        "paymentStore/getBusinessPaymentGatewayMethod",
        business_id
      );
      this.loading = false;
    },

    async businessPaymentDefaultChanges(defaultValue) {
      
      if (!this.forOperation) return;
      
      this.resetState();
      this.defaultLoading = true;
      let businessPaymentMethod = this.data;
      businessPaymentMethod.default = defaultValue;
      await this.$store.dispatch(
        "paymentStore/createOrUpdateBusinessPaymentGatewayMethod",
        businessPaymentMethod
      );

      let business_id =
        businessPaymentMethod?.payment_gateway_method_application?.business_id;

      await this.$store.dispatch(
        "paymentStore/getBusinessPaymentGatewayMethod",
        business_id
      );
      this.defaultLoading = false;
    },
    
    defaultChanges() {
      if (!this.forOperation) return;
      this.checkedDefaultValue = !this.checkedDefaultValue
    },
    
    resetState() {
      this.$store.commit("paymentStore/resetState", {
        errors: null,
      });
    },
  },
  computed: {
    switchStatus() {
      return this.checkedValue == 1
        ? this.$t("payment.settings.payment_provider.enabled")
        : this.$t("payment.settings.payment_provider.disabled");
    },
    checkedValue: {
      get() {
        return this.data.enabled == 1;
      },
      set(value) {
        this.businessPaymentChanges(value);
      },
    },
    checkedDefaultValue: {
      get() {
        return this.data.default == 1;
      },
      set(value) {
        this.businessPaymentDefaultChanges(value);
      },
    },
  },
};
</script>